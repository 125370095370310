import {formatFileSize, fieldNameToDots} from "@/framework/helpers";
import {svgIcon} from "@/framework/svg-icons";
import {trashIcon} from "@/icons";

export function bindUploadComponente(el, ctx, options) {
	if (!el.hasOwnProperty('_upload')) {

		let fileField = el.type === 'file' ? el : el.querySelector('[type=file]');

		if (!fileField) {
			console.warn('Upload has no upload field?!');
			return;
		}

		el._upload = true;


		let currentAttachments = options.current ? options.current : [];
		let isMultiple = fileField.hasAttribute('multiple');
		let max = fileField.hasAttribute('data-maxsize') ? parseInt(fileField.dataset.maxsize) : null;

		let uploadContainer = fileField.parentElement;

		if (uploadContainer.hasAttribute('data-upload') )
		{
			let upload = uploadContainer.getAttribute('data-upload');
			if (upload) {
				upload = JSON.parse(upload);
				if (upload && upload.mime && !fileField.hasAttribute('accept'))
				{
					if (Array.isArray(upload.mime)) {
						fileField.setAttribute('accept', upload.mime.join(','));
					}
					else if (typeof upload.mime === 'string') {
						fileField.setAttribute('accept', upload.mime);
					}
				}
			}
		}








		if (isMultiple) {
			fileField.parentElement.classList.add('multiple');

			let fileList = fileField.parentElement.querySelector('.file-list');
			if (!fileList) {
				fileList = document.createElement('div');
				fileList.classList.add('file-list');
				fileField.parentElement.prepend(fileList);
			}

			// Create current uploads as dummy
			if (currentAttachments && currentAttachments.length) {
				let labels = [];
				currentAttachments.forEach((f, x) => {

					let row = document.createElement('div');
					row.classList.add('file')


					let l = document.createElement('div');
					l.classList.add('file-label')
					l.innerHTML = '<div>'+ f.name+'</div>';


					let sc = document.createElement('div');

					let s = document.createElement('span');
					s.classList.add('badge');
					s.classList.add('badge-dark');
					s.innerText = formatFileSize( f.size );

					sc.appendChild(s);


					l.appendChild(sc);

					let removeBtn = document.createElement('div');
					removeBtn.setAttribute('data-filenum', x)
					removeBtn.classList.add('remove');
					removeBtn.innerHTML = svgIcon( trashIcon ); //svgIcon('trash');
					removeBtn.setAttribute('title', 'entfernen')
					removeBtn.addEventListener('click', (e) => {
						e.preventDefault();
						e.stopPropagation();

						/**
						 * @var {FileList} fl
						 */
						if (fileField.files && fileField.files.length &&
							e.currentTarget.dataset.hasOwnProperty('filenum')) {

							/**
							 * @var files {FileList}
							 */
							let files = fileField.files;
							let filenum = parseInt(e.currentTarget.dataset.filenum);

							/**
							 * @var {f} File
							 */
							let f = fileField.files[filenum];
							if (f && files) {

								let tmp = [];
								for (let xy = 0; xy < fileField.files.length; xy++) {
									if (xy != filenum) {
										tmp.push(fileField.files[xy]);
									}
								}

								fileField.files = new FileListItems(tmp);

								const form = fileField.closest('form');
								if (form && form.name && ctx.scope.hasOwnProperty('$models') &&
									ctx.scope.$models.hasOwnProperty(form.name)) {
									let n = fieldNameToDots( fileField.name );

									if (ctx.scope.$models[form.name].hasOwnProperty(n) ) {
										ctx.scope.$models[form.name][n] = fileField.files;
									}
								}

								e.currentTarget.closest('.file').remove();

								// refresh indexes
								fileList.querySelectorAll('.file .remove').forEach((el, index) => {
									el.setAttribute('data-filenum', index);
								});
							}

							if ( !fileField.files.length ) {
								fileField.parentNode.classList.remove('has-files');
							}
						}
						else {
							const form = fileField.closest('form');
							if (form && form.name && ctx.scope.hasOwnProperty('$models') &&
								ctx.scope.$models.hasOwnProperty(form.name)) {
								let n = fieldNameToDots(fileField.name);


								if (ctx.scope.$models[form.name].hasOwnProperty(n) ) {
									ctx.scope.$models[form.name][n] = fileField.files;
								}
							}
							// refresh indexes
							fileList.querySelectorAll('.file').forEach((el, index) => {
								el.remove()
							});
							fileField.parentNode.classList.remove('has-files');
						}

					});
					row.appendChild(removeBtn);
					row.appendChild(l);

					labels.push(row);
				})



				let fileList = fileField.parentElement.querySelector('.file-list');
				if (!fileList) {
					fileList = document.createElement('div');
					fileList.classList.add('file-list');

					fileField.parentElement.prepend(fileList);
				}

				fileList.innerHTML = '';
				labels.forEach((r) => {
					fileList.appendChild(r)
				});

				if (labels.length) {
					fileList.parentNode.classList.add('has-files');
				}

			}




			fileField.addEventListener('change', (e) => {
				if (e.target.files.length > 0) {
					let labels = [];

					// Uploadgröße gleich beachten und garnicht erst anzeigen
					if (max !== null && max > 0) {
						filterUploads(fileField, max);
					}


					for (let x = 0; x < fileField.files.length; x++) {
						let f = fileField.files[x];

						let row = document.createElement('div');
						row.classList.add('file')


						let l = document.createElement('div');
						l.classList.add('file-label')
						l.innerHTML = '<div>'+ f.name+'</div>';


						let sc = document.createElement('div');

						let s = document.createElement('span');
						s.classList.add('badge');
						s.classList.add('badge-dark');
						s.innerText = formatFileSize( f.size );

						sc.appendChild(s)


						l.appendChild(sc);

						let removeBtn = document.createElement('div');
						removeBtn.setAttribute('data-filenum', x)
						removeBtn.classList.add('remove');
						removeBtn.innerHTML = svgIcon(trashIcon); //svgIcon('trash');
						removeBtn.setAttribute('title', 'entfernen')

						removeBtn.addEventListener('click', (e) => {
							e.preventDefault();
							e.stopPropagation();

							/**
							 * @var {FileList} fl
							 */
							if (fileField.files && fileField.files.length &&
								e.currentTarget.dataset.hasOwnProperty('filenum')) {

								/**
								 * @var files {FileList}
								 */
								let files = fileField.files;
								let filenum = parseInt(e.currentTarget.dataset.filenum);

								/**
								 * @var {f} File
								 */
								let f = fileField.files[filenum];
								if (f && files) {

									let tmp = [];
									for (let xy = 0; xy < fileField.files.length; xy++) {
										if (xy != filenum) {
											tmp.push(fileField.files[xy]);
										}
									}

									fileField.files = new FileListItems(tmp);

									e.currentTarget.closest('.file').remove();

									// refresh indexes
									fileList.querySelectorAll('.file .remove').forEach((el, index) => {
										el.setAttribute('data-filenum', index)
									});
								}

								const form = fileField.closest('form');
								if (form && form.name && ctx.scope.hasOwnProperty('$models') &&
									ctx.scope.$models.hasOwnProperty(form.name)) {
									let n = fieldNameToDots( fileField.name );


									if (ctx.scope.$models[form.name].hasOwnProperty(n) ) {
										ctx.scope.$models[form.name][n] = fileField.files;
									}
								}

								if ( !fileField.files.length ) {
									fileField.parentNode.classList.remove('has-files');
								}
							}

						});



						row.appendChild(removeBtn);
						row.appendChild(l);

						labels.push(row);
					}

					let fileList = fileField.parentElement.querySelector('.file-list');
					if (!fileList) {
						fileList = document.createElement('div');
						fileList.classList.add('file-list');
						fileField.parentElement.prepend(fileList);
					}

					fileList.innerHTML = '';
					labels.forEach((r) => {
						fileList.appendChild(r)
					});

					const form = fileField.closest('form');
					if (form && form.name &&
						ctx.scope.hasOwnProperty('$models') &&
						ctx.scope.$models.hasOwnProperty(form.name)) {
						let n = fieldNameToDots( fileField.name );

						if (ctx.scope.$models[form.name].hasOwnProperty(n) ) {
							ctx.scope.$models[form.name][n] = fileField.files;
						}
					}

					if (labels.length) {
						e.target.parentNode.classList.add('has-files');
					}
				}
				else {
					let fileList = fileField.parentElement.querySelector('.file-list');
					if (fileList) fileList.innerHTML = '';


					const form = fileField.closest('form');
					if (form && form.name &&
						ctx.scope.hasOwnProperty('$models') &&
						ctx.scope.$models.hasOwnProperty(form.name)) {
						let n = fieldNameToDots( fileField.name );

						if (ctx.scope.$models[form.name].hasOwnProperty(n) ) {
							ctx.scope.$models[form.name][n] = fileField.files;
						}
					}


					e.target.parentNode.classList.remove('has-files');
				}
			});
		}
		else {
			let removeBtn = fileField.parentElement.querySelector('.remove');
			if (!removeBtn) {
				removeBtn = document.createElement('div')
				removeBtn.classList.add('remove');
				removeBtn.innerHTML = svgIcon(trashIcon); //svgIcon('trash');
			}

			removeBtn.setAttribute('title', 'entfernen');
			removeBtn.addEventListener('click', (e) => {
				e.preventDefault();
				/**
				 * @var {FileList} fl
				 */
				let fl = fileField.files;
				if (fl && fl.length) {
					const dt = new DataTransfer();
					fileField.files = dt.files;
					fileField.parentNode.classList.remove('has-files');
				}

				const form = fileField.closest('form');
				if (form && form.name &&
					ctx.scope.hasOwnProperty('$models') &&
					ctx.scope.$models.hasOwnProperty(form.name)) {


					let n = fieldNameToDots( fileField.name );

					if (ctx.scope.$models[form.name].hasOwnProperty(n) ) {
						ctx.scope.$models[form.name][n] = fileField.files;
					}
				}

			});





			fileField.parentElement.prepend(removeBtn);

			fileField.addEventListener('change', (e) => {
				handleUploadField(e)
			});
		}


	}
}

function FileListItems (files) {
	let b = new ClipboardEvent("").clipboardData || new DataTransfer();
    let i, len = files.length;
	for (i = 0; i<len; i++) {
        b.items.add(files[i])
    }
	return b.files
}

function filterUploads(inputEl, maxBytes) {
	let inFiles = inputEl.files;
	let tmp = [], x;

	for (x = 0; x < inFiles.length; x++) {
		let f = inFiles[x];
		if (f.size > maxBytes) {
			continue;
		}
		tmp.push(inFiles[x]);
	}

	inputEl.files = new FileListItems(tmp);
}


function handleUploadField(e) {
	if (e.target.files) {
		if (e.target.files.length > 0) {
			e.target.parentNode.classList.add('has-files');
		}
		else {
			e.target.parentNode.classList.remove('has-files');
		}
	}
}

export const componentUpload = (el, ctx, exp, get, options) => {
	if (el.hasOwnProperty('__mounted')) return;
	el.__mounted = true;
	el.setAttribute('data-pre', '');
	bindUploadComponente(el, ctx, options);

};
