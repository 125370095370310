import Cookies from "js-cookie";
function switchDarkmodeTo(makeDark = false, transitionDuration = 400) {
	if (makeDark) {
		document.body.setAttribute('data-theme', 'dark');
		document.documentElement.setAttribute('data-theme', 'dark');
	} else {
		document.body.removeAttribute('data-theme');
		if (document.documentElement.hasAttribute('data-theme')) {
			document.documentElement.removeAttribute('data-theme');
		}
	}

	document.documentElement.classList.add('color-theme-in-transition');

	setTimeout(() => {
		document.documentElement.classList.remove('color-theme-in-transition');
	}, transitionDuration);

	let themeSwitch = document.querySelector('.theme-switch');

	if (themeSwitch) {
		themeSwitch.style.top = (event.target.offsetTop + event.target.offsetHeight/2) + 'px';
		themeSwitch.style.left = event.pageX + 'px';
		themeSwitch.classList.add('animated');

		setTimeout(() => {
			themeSwitch.classList.remove('animated');
		}, transitionDuration);
	}
}


export const componentToggleDarkmode = (el, ctx, exp, get, options) => {
	if (el.hasOwnProperty('__mounted')) return;

	el.__mounted = true;
	const scope = ctx.scope;
	el.addEventListener('click', (event) => {
		event.preventDefault();
		event.stopImmediatePropagation();
		scope.darkmode = !scope.darkmode;
		Cookies.set('darkmode', scope.darkmode, {
			expires: 365,
		});
		switchDarkmodeTo(scope.darkmode, 400);
	});
};