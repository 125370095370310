export const componentNotification = (el, ctx, exp, get, options) => {

    let config = Object.assign({
        delay: 0,
        forcevisible: 0
    }, options);

    let foreceVisible = config.hasOwnProperty('forcevisible') && config.forcevisible;

    el.classList.add('notify-container');

    setTimeout(() => {
        let t;
        el.classList.add('show');
        if (!foreceVisible) {
            if (config.hasOwnProperty('delay') && config.delay > 0 ) {
                t = setTimeout(() => {
                    el.classList.remove('show');
                }, config.delay);
            }

            el.addEventListener('click', (e) => {
                if (t) clearTimeout(t);
                el.classList.remove('show');
            });
        }
    }, 150);
};
