import {svgIcon} from "@/framework/svg-icons";
import {trashIcon} from "@/icons";

export function bindAvatarUploadComponente(el, ctx, options) {
    if (!el.hasOwnProperty('_upload')) {

        let fileField = el.type === 'file' ? el : el.querySelector('[type=file]');

        if (!fileField) {
            console.warn('Upload has no upload field?!');
            return;
        }

        el._upload = true;

        let inForm = fileField.closest('form');
        let hiddenField = fileField.parentElement.querySelector('[name="current_'+ fileField.name +'"]');
        let current = options.current ? options.current : null;
        let max = fileField.hasAttribute('data-maxsize') ? parseInt(fileField.dataset.maxsize) : null;

        let uploadContainer = fileField.parentElement;
        if (uploadContainer.hasAttribute('data-upload') )
        {
            let upload = uploadContainer.getAttribute('data-upload');
            if (upload) {
                upload = JSON.parse(upload);
                if (upload && upload.mime && !fileField.hasAttribute('accept'))
                {
                    if (Array.isArray(upload.mime)) {
                        fileField.setAttribute('accept', upload.mime.join(','));
                    }
                    else if (typeof upload.mime === 'string') {
                        fileField.setAttribute('accept', upload.mime);
                    }
                }
            }
        }


        let removeBtn = fileField.parentElement.querySelector('.remove');
        if (!removeBtn) {
            removeBtn = document.createElement('div');
            removeBtn.classList.add('remove');




            removeBtn.innerHTML = svgIcon(trashIcon);
        }




        fileField.parentElement.prepend(removeBtn);



        let imgContainer = document.createElement('div');
        imgContainer.classList.add('avatar-container');

        if (current) {
            let img = document.createElement('img');
            img.setAttribute('src', current);
            imgContainer.appendChild(img);
            fileField.parentNode.classList.add('has-files');
        }

        fileField.parentElement.append(imgContainer);


        removeBtn.setAttribute('data-tooltip', 'entfernen');
        removeBtn.addEventListener('click', (e) => {
            e.preventDefault();
            /**
             * @var {FileList} fl
             */
            let fl = fileField.files;
            if (fl && fl.length) {
                const dt = new DataTransfer();
                fileField.files = dt.files;
            }

            let img = imgContainer.querySelector('img');
            if (img) {
                img.remove();

                if (hiddenField) {
                    hiddenField.value = '';
                }
            }

            fileField.parentNode.classList.remove('has-files');
        });


        fileField.addEventListener('change', (e) => {
            handleUploadField(e);
            let img = imgContainer.querySelector('img')
            if (e.target.files) {
                if (e.target.files.length > 0) {
                    const reader = new FileReader();
                    reader.addEventListener("load", () => {
                        const uploaded_image = reader.result;
                        if (img) {
                            img.setAttribute('src', uploaded_image);
                        }
                        else {
                            img = document.createElement('img');
                            img.setAttribute('src', uploaded_image);
                            imgContainer.appendChild(img);
                        }
                    });

                    reader.readAsDataURL(e.target.files[0]);

                }
                else {
                    if (img) {
                        img.remove();
                    }
                }
            }

        });



        if (inForm) {
            inForm.addEventListener('reset', () => {
                if (current) {
                    let img = imgContainer.querySelector('img')
                    if (!img) {
                        img = document.createElement('img');
                        img.setAttribute('src', current);
                        imgContainer.appendChild(img);
                    }
                    else {
                        img.setAttribute('src', current);
                    }
                    fileField.parentNode.classList.add('has-files');
                }
            });
        }


    }
}

function handleUploadField(e) {
    if (e.target.files) {
        if (e.target.files.length > 0) {
            e.target.parentNode.classList.add('has-files');
        }
        else {
            e.target.parentNode.classList.remove('has-files');
        }
    }
}

export const componentAvatarUpload = (el, ctx, exp, get, options) => {
    bindAvatarUploadComponente(el, ctx, options);
};
