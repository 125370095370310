
import GLightbox from '~/glightbox/dist/js/glightbox.min';
import {evaluate} from '@/framework/eval';
import {isObject} from '@/framework/utils';

export const componentLightbox = (el, ctx, exp, get, options) => {
	if (el.hasOwnProperty('__mounted')) return;
	el.__mounted = true;

	if (typeof options !== "object") {
		options = {
			src: null,
			desc: null,
			descPosition: null
		};
	}


	el.classList.add('cursor-zoom');

	el.addEventListener('click', (e) => {
		e.preventDefault();
		if (!e.currentTarget.hasOwnProperty('lightbox')) {
			e.currentTarget.lightbox = GLightbox({
				openEffect: 'zoom',
				closeEffect: 'zoom',
				cssEfects: {
					// This are some of the animations included, no need to overwrite
					fade: {in: 'fadeIn', out: 'fadeOut'},
					zoom: {in: 'zoomIn', out: 'zoomOut'}
				},
				touchNavigation: true,
				loop: false,
				autoplayVideos: false,
				zoomable: false,
				draggable: false,
			});

			e.currentTarget.lightbox.setElements([{
				href: options.src ?? '',
				description: options.desc ?? '',
				descPosition: options.descPosition ?? 'bottom',
				'type': 'image',
				width: '90vw',
				zoomable: false,
				draggable: false,
			}]);
		}
		e.currentTarget.lightbox.openAt(0);
	})
};

export const componentLightboxGallery = (el, ctx, exp, get, options) => {
	if (el.hasOwnProperty('__mounted')) return;
	el.__mounted = true;

	if (typeof options !== "object") {
		options = {
			src: null,
			desc: null,
			descPosition: null
		};
	}


	const gallery = GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: true,
	});

	let allElements = [];
	el.querySelectorAll('[data-lightbox-options]').forEach((item, index) => {

		if (item.dataset.lightboxOptions) {

			let v = evaluate(ctx.scope, item.dataset.lightboxOptions);

			if (isObject(v))
			{
				let props = {
					description: v.desc ?? '',
					descPosition: v.descPosition ?? 'bottom',
					href: v.src ?? '',
					zoomable: v.zoomable ?? false,
					draggable: v.draggable ?? false
				};

				if (item.tagName === 'IMG') {
					props.type = 'image'
				}
				else if (item.tagName === 'VIDEO') {
					props.type = 'video'
				}

				if (props.href) {
					allElements.push(props);
                    item.addEventListener('click', () => gallery.openAt(index), true);
				}
			}
		}
	});

	if (allElements.length) {
        gallery.setElements(allElements);
    }
}
