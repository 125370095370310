
import * as escape from "sceditor/src/lib/escape";
import * as dom from "sceditor/src/lib/dom";
require('sceditor/minified/sceditor.min');
require('sceditor/src/formats/bbcode');
require('sceditor/src/formats/xhtml');
//
require('sceditor/src/icons/material');
// // require('sceditor/src/icons/monocons');
require('sceditor/languages/en');
require('sceditor/languages/de');
// require('sceditor/languages/fr');
// require('sceditor/languages/it');
// require('sceditor/languages/pl');
// require('sceditor/languages/es');



const _templates = {
	code: '<div><label for="type">{type}</label> <select id="type" class="form-control">' +
		'<option value="plain">Plain</option>' +
		'<option value="html">HTML</option>' +
		'<option value="js">Javascript</option>' +
		'<option value="css">CSS</option>' +
		'<option value="scss">SCSS</option>' +
		'<option value="sass">SASS</option>' +
		'<option value="yaml">YAML</option>' +
		'<option value="php">PHP</option>' +
		'</select></div>' +
		'<div><textarea cols="20" rows="7" id="txt" class="form-control"></textarea></div>' +
		'<div><input type="button" class="button" value="{insert}" /></div>',
};



function tmpl(name, params, createHtml) {
	var template = _templates[name];

	Object.keys(params).forEach(function (name) {
		template = template.replace(
			new RegExp(escape.regex('{' + name + '}'), 'g'), params[name]
		);
	});

	if (createHtml) {
		template = dom.parseHTML(template);
	}

	return template;
}



export function bindBBcodeEditor(el, ctx, options) {
	if (el && !el.mounted) {
		el.mounted = true;
		let icons = 'material';
		let form = el.closest('form');
		const originalValue = el.value;


        let isInATab = false;
        if (el.closest('[role="tabpanel"]') || el.closest('.tab-pane')) {
            isInATab = true;
        }


		console.log('scope', ctx.scope, ctx);

		const setModel = (value) => {
			if (ctx.scope.$models.hasOwnProperty(form.name)) {
				if (ctx.scope.$models[form.name].hasOwnProperty(el.name)) {
					ctx.scope.$models[form.name][el.name] = value;
				}
			}
		};


		const utils = window.sceditor.utils;


		window.sceditor.command.set('undo', {
			state: function () {
				return redoPosition < undoStates.length - 1 ? 0 : -1;
			},
			exec: function () {
				base.undo();
			},
			tooltip: 'Undo'
		});

		window.sceditor.command.set('redo', {
			state: function () {
				return redoPosition > 0 ? 0 : -1;
			},
			exec: function () {
				window.sceditor.redo();
			},
			tooltip: 'Redo'
		});

		window.sceditor.command.set('code', {
			format: function (element, content) {
				let	type = attr(element, 'type');
				return '[code type="' + type + '"]' + content + '[/code]';
			},
			exec: function (caller) {
				let	val, type,
					content = dom.createElement('div'),
					editor  = this;

				dom.appendChild(content, tmpl('code', {
					type: 'Typ',
					insert: editor._('Insert')
				}, true));

				dom.on(content, 'click', '.button', function (e) {
					type = dom.find(content, '#type')[0].value;
					val = dom.find(content, '#txt')[0].value;
					if (val) {

						editor.wysiwygEditorInsertHtml('<code class="language-'+ type +'">'+ val +'</code>');
					}

					editor.closeDropDown(true);
					e.preventDefault();
				});

				editor.createDropDown(caller, 'code', content);
			}
		});

		window.sceditor.formats.bbcode.set('code', {
			tags: {
				code: null
			},
			isInline: false,
			allowedChildren: ['#', '#newline'],
			quoteType: 1,
			format: function (element, content) {
				let	type = element.getAttribute('class');
				let cls = '';
				cls = type ? type.replace(/.*language\-([a-z0-9]+).*/ig, '$1') : '';


				if (cls) {
					return '[code lang="' + cls + '"]' + content + '[/code]';
				}
				else {
					return '[code]' + content + '[/code]';
				}
			},
			html: function (token, attrs, content) {
				console.log(attrs);
				return '<code class="language-'+ attrs.lang +'">'+ content +'</code>';
			}
		});

		let buttons = 'bold,italic,underline,strike,subscript,superscript|' +
			'left,center,right,justify|removeformat|' +
			'cut,copy,pastetext|bulletlist,orderedlist,indent,outdent|' +
			'code,quote|horizontalrule,image,email,link,unlink|' +
			'emoticon,youtube|maximize,source';


		const emoIcons = {
			dropdown: {
				':)': 'img/emoticons/smile.png',
				':angel:': 'img/emoticons/angel.png',
				':angry:': 'img/emoticons/angry.png',
				'8-)': 'img/emoticons/cool.png',
				':\'(': 'img/emoticons/cwy.png',
				':ermm:': 'img/emoticons/ermm.png',
				':D': 'img/emoticons/grin.png',
				'<3': 'img/emoticons/heart.png',
				':(': 'img/emoticons/sad.png',
				':O': 'img/emoticons/shocked.png',
				':P': 'img/emoticons/tongue.png',
				';)': 'img/emoticons/wink.png'
			},
			more: {
				':alien:': 'img/emoticons/alien.png',
				':blink:': 'img/emoticons/blink.png',
				':blush:': 'img/emoticons/blush.png',
				':cheerful:': 'img/emoticons/cheerful.png',
				':devil:': 'img/emoticons/devil.png',
				':dizzy:': 'img/emoticons/dizzy.png',
				':getlost:': 'img/emoticons/getlost.png',
				':happy:': 'img/emoticons/happy.png',
				':kissing:': 'img/emoticons/kissing.png',
				':ninja:': 'img/emoticons/ninja.png',
				':pinch:': 'img/emoticons/pinch.png',
				':pouty:': 'img/emoticons/pouty.png',
				':sick:': 'img/emoticons/sick.png',
				':sideways:': 'img/emoticons/sideways.png',
				':silly:': 'img/emoticons/silly.png',
				':sleeping:': 'img/emoticons/sleeping.png',
				':unsure:': 'img/emoticons/unsure.png',
				':woot:': 'img/emoticons/w00t.png',
				':wassat:': 'img/emoticons/wassat.png'
			},
			hidden: {
				':whistling:': 'img/emoticons/whistling.png',
				':love:': 'img/emoticons/wub.png'
			}
		};

		if (el.dataset.icons) {
			icons = el.dataset.icons;
		}

		if (el.dataset.buttons && typeof el.dataset.buttons === "string" && el.dataset.buttons.trim()) {
			buttons = el.dataset.buttons;
		}

		let style = '';
		if (Array.isArray(window.Laravel.themeStyles)) {
			style = window.Laravel.themeStyles[0];
		}

		let emoticonsRoot = '';
		if (window.Laravel.baseUrl) {
			emoticonsRoot = window.Laravel.baseUrl.replace(/\/$/g, '') +'/';
		}

		window.sceditor.create(el, {
			locale: window.Laravel.locale ? window.Laravel.locale.replace(/(\-|_)?([A-Z]+)$/g, '') : 'en',
			format: 'bbcode',
			icons: icons,
			plugins: 'undo',
			toolbar: buttons,
			emoticonsRoot: emoticonsRoot,
			emoticons: emoIcons,
			spellcheck: true,
			autoExpand: true,
			enablePasteFiltering: true,
			width: '100%',
			height: 'auto',
			resizeMinWidth: '100%',
			resizeMaxWidth: '100%',
			resizeMaxHeight: -1,
			resizeWidth: false,
			rtl: false,
			autoUpdate: true,
			style: style
		});


		// const inst = el._sceditor;

		const main = document.querySelector('.app > nav');
        /** */
		const edInst = window.sceditor.instance(el);
		let toolbar;

		// Make toolbar sticky
		if (el.parentNode && main) {
			toolbar = el.parentNode.querySelector('.sceditor-container > .sceditor-toolbar');
			if (toolbar) {
				toolbar.style.position = 'sticky';
				toolbar.style.top = main.offsetHeight +'px';
			}
		}

		// Iframe Body
		let body = edInst.getBody();
		if (body) {
			body.classList.add('sceditor-iframe-body');
			let iframeHead = body.parentNode.querySelector('head');

			if (ctx.scope.darkmode) {
				body.setAttribute('data-theme', 'dark');
				if (iframeHead) {
					body.parentNode.setAttribute('data-theme', 'dark');
				}
			}
			else {
				body.setAttribute('data-theme', '');
				if (iframeHead) {
					body.parentNode.setAttribute('data-theme', '');
				}
			}

			if (Array.isArray(window.Laravel.themeStyles) && window.Laravel.themeStyles.length > 1) {

				window.Laravel.themeStyles.forEach((styleUrl, x) => {
					if (x > 0) {
						let link = document.createElement('link');
						link.setAttribute('rel', 'stylesheet');
						link.setAttribute('href', styleUrl);
						iframeHead.appendChild(link);
					}
				});
			}

			form.addEventListener('reset', () => {
				setModel(originalValue);
				edInst.val(originalValue);

			});

			ctx.effect(() => {

				if (ctx.scope.darkmode) {
					edInst.getBody().setAttribute('data-theme', 'dark');
					if (iframeHead) {
						body.parentNode.setAttribute('data-theme', 'dark');
					}
				}
				else {
					edInst.getBody().setAttribute('data-theme', '');
					if (iframeHead) {
						body.parentNode.setAttribute('data-theme', '');
					}
				}
			});
		}

		let area = el.parentNode.querySelector('.sceditor-container > textarea');
        let iframe = el.parentNode.querySelector('.sceditor-container > iframe');

        const syncHeight = () => {
            if (iframe) {
                iframe.style.height = '';
                iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
            }

			if (area) {
				area.style.height = '';
				area.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
			}
        };


		const changeEvent = new Event('change');
		edInst.bind('keyup', (e) => {
			// let iframe = el.parentNode.querySelector('.sceditor-container > iframe');
			// if (iframe) iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';

			edInst.updateOriginal();

			setModel(edInst.val());

			el.dispatchEvent(changeEvent);
		});

		edInst.bind('keydown', (e) => {

			edInst.updateOriginal();
			el.dispatchEvent(changeEvent);

			syncHeight()
		});

		edInst.bind('keypress', (e) => {
			// let iframe = el.parentNode.querySelector('.sceditor-container > iframe');
            // if (iframe) {
            //     iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
            // }
			edInst.updateOriginal();
			el.dispatchEvent(changeEvent);


		});

		edInst.bind('blur', (e) => {
			edInst.updateOriginal();
			setModel(edInst.val());
			el.dispatchEvent(changeEvent);

			el.parentNode.querySelector('.sceditor-container').classList.remove('focus');
            let formField = el.closest('.form-field');
            if (formField) {
                formField.classList.remove('is-focused');
            }
		});

		edInst.bind('focus', (e) => {
			edInst.updateOriginal();
			el.dispatchEvent(changeEvent);
			el.parentNode.querySelector('.sceditor-container').classList.add('focus');

            let formField = el.closest('.form-field');
            if (formField) {
                formField.classList.add('is-focused');
            }
		});

		edInst.bind('nodechanged', (e) => {
            syncHeight()
			edInst.updateOriginal();
		});

		edInst.bind('valuechanged', (e) => {
            syncHeight()
			edInst.updateOriginal();
		});

		edInst.bind('selectionchanged', (e) => {
            syncHeight()
			edInst.updateOriginal();
		});

		edInst.bind('pasteraw', (e) => {
            syncHeight()
			edInst.updateOriginal();
			el.dispatchEvent(changeEvent);
		});

		edInst.bind('paste', (e) => {
            syncHeight()
			edInst.updateOriginal();
			el.dispatchEvent(changeEvent);
		});

		setTimeout(() => {
			syncHeight()
		}, 150)




        if (area) {
            area.addEventListener('keydown', (e) => {
                if (e.keyCode === 9) {
                    e.preventDefault();
                    area.setRangeText(
                        '  ',
                        area.selectionStart,
                        area.selectionStart,
                        'end'
                    );
                }
            });

            let formField = area.closest('.form-field');
            if (formField) {
                formField.classList.add('is-bbcode-editor');
            }
        }




        // Little fix for auto height the bbcode editor
        if (isInATab) {
            let tab = el.closest('.tab-pane');
            if (!tab) {
                tab = el.closest('[role="tabpanel"]');
            }

            if (tab) {
                const intersectionObserver = new IntersectionObserver((entries) => {
                    if (entries[0].target && entries[0].target.classList.contains('active')) {
                        syncHeight();
                    }
                });

                // Respond to visibility changes that are handled via internal/external CSS stylesheets
                intersectionObserver.observe(tab);
            }
        }
    }
}







export const componentBBCodeEditor = (el, ctx, exp, get, options) => {
	if (el.hasOwnProperty('__mounted')) return;
	el.__mounted = true;
	el.setAttribute('data-pre', '');
	bindBBcodeEditor(el, ctx);

};
